.pricing-container {
  padding: 120px 20px 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.pricing-header {
  text-align: center;
  margin-bottom: 40px;
}

.pricing-header h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 16px;
}

.pricing-header p {
  font-size: 1.2rem;
  color: #666;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px;
}

.pricing-card {
  background: white;
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.pricing-card.popular {
  border: 2px solid #2ECC71;
}

.popular-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background: #2ECC71;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.9rem;
}

.pricing-card h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 8px;
}

.plan-description {
  color: #666;
  margin-bottom: 24px;
}

.price {
  margin: 24px 0;
}

.amount {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.period {
  color: #666;
  font-size: 1.1rem;
}

.features {
  list-style: none;
  padding: 0;
  margin: 24px 0;
}

.features li {
  padding: 8px 0;
  color: #555;
}

.features li::before {
  content: "✓";
  color: #2ECC71;
  margin-right: 8px;
}

.cta-button {
  width: 100%;
  padding: 12px;
  background: #2ECC71;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.cta-button:hover {
  background: #25a25a;
}

/* Add this to create space above the button */
.pricing-card ul {
  margin-bottom: auto;  /* This pushes the button to the bottom */
  padding-bottom: 24px; /* Add some space between list and button */
}

/* Style the button container */
.pricing-card .cta-button {
  width: 100%;
  margin-top: auto;  /* This ensures buttons align at the bottom */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pricing-grid {
    grid-template-columns: 1fr;
  }
  
  .pricing-card {
    margin-bottom: 20px;
  }
} 