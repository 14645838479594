@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

/* Custom Reset */
body {
     font-family: "Inter", sans-serif;
     margin: 0;
}

a.active,
a:focus,
button:focus,
button.active {
     outline: none;
     font-size-adjust: none;
}

a {
     text-decoration: none;
     transition: all 0.5s ease-in-out;
}

a:focus,
a:hover,
a:active {
     outline: 0;
     box-shadow: none;
}

a:focus,
a:hover {
     text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
     margin: 0;
     padding: 0;
}

a {
     text-decoration: none;
     transition: all 0.5s ease-in-out;
}

a:hover {
     text-decoration: none;
}

ul,
ol {
     margin: 0;
     padding: 0;
     list-style: none;
}

ul li {
     list-style: none;
}

p {
     margin: 0;
     padding: 0;
}

input:focus,
select:focus,
textarea:focus {
     outline: none;
     font-size-adjust: none;
}

img {
     border-style: none;
     display: inline-block;
     max-width: 100%;
     height: auto;
}

* {
     box-sizing: border-box;
}

/*  */
.container {
     max-width: 1320px;
     padding: 0px 20px;
     margin: 0 auto;
}

.navbar {
     display: flex;
     align-items: center;
     justify-content: space-between;
     background-color:#050B1B;
     margin: 16px 32px;
     padding: 15px 32px;
     border-radius: 24px;
     backdrop-filter: blur(10px);
}

.nav-items {
     display: flex;
     align-items: center;
     grid-gap: 40px;
     justify-content: center;
     margin: 0 auto;
}

.hamburger {
     display: none;
}

.navbar-nav {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex: 1;
}

.nav-items .nav-link {
     color: #FFF;
     display: block;
     font-size: 16px;
     font-weight: 600;
     line-height: 24px;
}

.btn-success {
     color: #FFF;
     font-family: "Plus Jakarta Sans", sans-serif;
     font-size: 15px;
     font-weight: 700;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 12px 16px;
     border-radius: 99px;
     background: #2ECC71;
}

.btn-success:hover {
     background-color: #fff;
     color: #2ECC71;
}

.btn-default:hover {
     background-color: #2ECC71;
     color: #fff;
}

.nav-items .nav-link:hover {
     color: #2ECC71;
}

.footer-bottom a:hover {
     color: #fff;
}

.btn-default {
     color: #2ECC71;
     font-family: "Plus Jakarta Sans", sans-serif;
     font-size: 15px;
     font-weight: 700;
     border-radius: 99px;
     background: #FFF;
     display: flex;
     padding: 12px 16px;
     justify-content: center;
     align-items: center;
}

.btns-list {
     display: flex;
     align-items: center;
     grid-gap: 16px;
     margin: 24px 0px 110px;
}

.text-box h1 {
     color: #FFF;
     font-family: "Plus Jakarta Sans";
     font-size: 64px;
     font-weight: 700;
     margin: 8px 0px 16px;
     line-height: 120%;
     max-width: 677px;
     letter-spacing: -1.28px;
}

.hero-sec .tag-line {
     color: #2ECC71;
     font-family: "Plus Jakarta Sans";
     font-size: 13px;
     display: block;
     font-weight: 600;
     line-height: 145%;
     letter-spacing: 2.6px;
     text-transform: uppercase;
}

.hero-sec .text-box p {
     color: #FFF;
     font-family: "Plus Jakarta Sans";
     font-size: 15px;
     font-weight: 500;
     max-width: 580px;
     line-height: 165%;
}

.number-boxes {
     display: flex;
     align-items: center;
     max-width: 1086px;
}

.number-box {
     width: 33.33%;
     border-left: 2px solid #2ECC71;
     padding: 0px 40px;
}

.number-box h4 {
     color: #FFF;
     font-family: "Plus Jakarta Sans";
     font-size: 32px;
     font-weight: 800;
     line-height: 108%;
     margin-bottom: 16px;
}

.number-box h4 span {
     color: #F3F4F6;
     font-family: "Plus Jakarta Sans";
     font-size: 15px;
     font-weight: 700;
     line-height: 108%;
     letter-spacing: 0.45px;
     text-transform: uppercase;
}

.number-box p {
     line-height: 165%;
}

.hero-sec {
     background-image: url(../assets/fpimages/hero-bg.png);
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center;
     padding: 224px 0px 96px;
}

header {
     position: absolute;
     top: 0;
     left: 0;
     background-color: transparent;
     width: 100%;
     padding: 0;
     margin-top: 16px;
     border-top: none;
}

.row {
     display: flex;
}

.col-3 {
     width: 25%;
}

.col-9 {
     width: 75%;
}

.partner-sec .row {
     border-bottom: 1px solid #E8E8E8;
     padding: 42px 0px;
}

.partner-logo {
     display: flex;
     align-items: center;
     justify-content: space-between;
     grid-gap: 10px;
}

.partner-sec span {
     color: #576B76;
     font-family: "Plus Jakarta Sans";
     font-size: 14px;
     font-weight: 700;
     display: block;
     max-width: 102px;
     line-height: 135%;
}

footer .row {
     grid-gap: 90px;
}

.col-7 {
     width: 59%;
}

.col-5 {
     width: 41%;
}

.para {
     color: #BABABA;
     font-family: "Plus Jakarta Sans";
     font-size: 14px;
     font-weight: 500;
     line-height: 185%;
     margin-top: 32px;
     max-width: 480px;
}

footer {
     background: #050B1B;
     padding: 120px 0px 32px;
}

.footer-items {
     display: flex;
     grid-gap: 16px;
     justify-content: space-between;
}

.footer-items h5 {
     color: #FFF;
     font-family: "Plus Jakarta Sans";
     font-size: 16px;
     font-weight: 600;
     line-height: 125%;
     margin-bottom: 20px;
}

.footer-items .footer-link {
     display: block;
     color: #FFF;
     font-family: "Plus Jakarta Sans";
     font-size: 14px;
     font-weight: 400;
     line-height: 19px;
     display: block;
     margin-bottom: 16px;
}

.social-links ul {
     display: flex;
     align-items: center;
     grid-gap: 32px;
}

.social-links {
     border-top: 1px solid rgb(255, 255, 255, 0.10);
     border-bottom: 1px solid rgb(255, 255, 255, 0.10);
     padding: 32px 0px;
     margin: 96px 0px 48px;
}

.footer-bottom {
     display: flex;
     align-items: center;
     justify-content: space-between;
}

.footer-bottom p,
.footer-bottom a {
     color: #AFAFAF;
     font-family: "Plus Jakarta Sans";
     font-size: 14px;
     font-weight: 400;
     line-height: 19px;
}

.footer-bottom ul {
     display: flex;
     align-items: center;
     grid-gap: 32px;
}



.quest-sec {
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 64px 0;
     background-color: #f9f9f9; /* Adjust background color if necessary */
   }
   
   .quest-container {
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: flex-start;
     gap: 32px;
     max-width: 1200px;
     padding: 0;
   }
   
   .quest-box {
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: flex-start;
     gap: 32px;
   }
   
   .quest-image {
     width: 496px;
     height: 447px;
     object-fit: cover;
     border-radius: 8px; /* Optional: for rounded corners */
   }
   
   .quest-text-box {
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     align-items: flex-start;
     gap: 32px;
     max-width: 496px;
   }
   
   .quest-tag-line {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 13px;
     font-weight: 600;
     line-height: 145%;
     text-transform: uppercase;
     letter-spacing: 0.2em;
     color: #2ecc71;
   }
   
   .quest-title {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 40px;
     font-weight: 700;
     line-height: 125%;
     color: #001831;
     margin: 0;
   }
   
   .quest-description {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 15px;
     font-weight: 500;
     line-height: 185%;
     color: #576b76;
   }


   /* Section container */
.scenario-sec {
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 136px 320px;
     background: #f3f3f3;
   }
   
   /* Inner container */
   .scenario-container {
     display: flex;
     flex-direction: row;
     align-items: center;
     gap: 80px;
     max-width: 1920px;
     width: 100%;
   }
   
   /* Text box */
   .scenario-text-box {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
     gap: 32px;
     max-width: 496px;
   }
   
   .scenario-tag-line {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 13px;
     font-weight: 600;
     line-height: 145%;
     text-transform: uppercase;
     letter-spacing: 0.2em;
     color: #2ecc71;
   }
   
   .scenario-title {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 40px;
     font-weight: 700;
     line-height: 125%;
     color: #001831;
     margin: 0;
   }
   
   .scenario-description {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 15px;
     font-weight: 500;
     line-height: 185%;
     color: #576b76;
   }
   
   /* Button */
   .scenario-btn {
     display: inline-flex;
     justify-content: center;
     align-items: center;
     padding: 16px;
     gap: 8px;
     width: 116px;
     height: 43px;
     background: #2ecc71;
     border-radius: 99px;
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 15px;
     font-weight: 700;
     color: #ffffff;
     text-decoration: none;
   }
   
   /* Image box */
   .scenario-image-box {
     flex: none;
     order: 1;
   }
   
   .scenario-image {
     width: 704px;
     height: 560px;
     object-fit: cover;
     border-radius: 16px;
   }
   

   /* Platform Section */
.platform-sec {
     position: relative;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     padding: 184px 300px;
     gap: 56px;
     isolation: isolate;
     width: 100%;
     height: 965px;
     background: linear-gradient(180deg, #050B1B 0%, #010510 100%), #050B1B;
     border-radius: 0px;
     overflow: hidden;
   }
   
   /* Glow Background */
   .glow-bg {
     position: absolute;
     width: 150%; /* Increased size to ensure edges are not visible */
     height: 150%;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     z-index: 0;
     overflow: hidden; /* Ensures no spillover from children */
   }
   
   /* Vector Glow */
   .vector {
     position: absolute;
     width: 100%; /* Matches the glow-bg size */
     height: 100%; /* Matches the glow-bg size */
     background: radial-gradient(
       50% 50% at 50% 50%,
       #2ECC71 0%,
       #6AF6A5 58.5%,
       #050B1B 100%
     );
     opacity: 0.3;
     filter: blur(250px);
     border-radius: 50%; /* Ensures smooth circular glow */
   }
   /* Container */
   .platform-container {
     z-index: 1;
     display: flex;
     flex-direction: column;
     align-items: center;
     text-align: center;
     gap: 48px;
   }
   
   /* Header */
   .platform-header {
     display: flex;
     flex-direction: column;
     align-items: center;
     gap: 16px;
   }
   
   .platform-tag-line {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 13px;
     font-weight: 600;
     line-height: 145%;
     text-transform: uppercase;
     color: #2ECC71;
     letter-spacing: 0.2em;
   }
   
   .platform-title {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 40px;
     font-weight: 700;
     line-height: 125%;
     color: #FFFFFF;
     letter-spacing: -0.02em;
   }
   
   /* Steps */
   .platform-steps {
     display: flex;
     justify-content: space-between;
     gap: 24px;
     width: 100%;
   }
   
   .platform-step {
     display: flex;
     flex-direction: column;
     justify-content: flex-end;
     align-items: flex-start;
     padding: 64px 48px 32px;
     gap: 48px;
     width: 244.8px;
     height: 456px;
     background: linear-gradient(0deg, rgba(46, 204, 113, 0.15), rgba(46, 204, 113, 0.15)), #050B1B;
     backdrop-filter: blur(36px);
     border-radius: 16px;
     position: relative;
   }
   
   .step-number {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 48px;
     font-weight: 800;
     line-height: 60px;
     color: #2ECC71;
   }
   
   .step-title {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 23px;
     font-weight: 700;
     line-height: 29px;
     color: #2ECC71;
     margin-top: 16px;
   }
   
   .step-description {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 13.5px;
     font-weight: 500;
     line-height: 185%;
     color: #FFFFFF;
   }
   
   /* Button */
   .platform-button {
     margin-top: 32px;
   }
   
   .get-started-btn {
     display: inline-block;
     padding: 12px 24px;
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 15px;
     font-weight: 700;
     color: #FFFFFF;
     background: #2ECC71;
     border-radius: 99px;
     text-decoration: none;
     transition: background 0.3s ease;
   }
   
   .get-started-btn:hover {
     background: #6AF6A5;
   }
   

   /* Team Section - Updated styles */
.team-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 0; /* Removed horizontal padding */
    gap: 40px;
    width: 100%;
    background-color: #fefefe;
}

.team-title {
    padding: 0 48px; /* Add padding to just the title */
    width: 100%;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    color: #001831;
}

.team-container {
    margin: 0 48px; /* Add margin instead of padding on container */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: calc(100% - 96px); /* Account for margins */
    background: #f3f3f3;
    border-radius: 8px;
    padding: 32px;
    gap: 32px;
    min-height: 500px;
}

/* AboutPage specific styles */
.about-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 40px 0;
}

.about-container section {
    margin-bottom: 48px;
}

/* Team List adjustments */
.team-list {
     display: flex;
     flex-direction: column;
     gap: 16px;
     width: 35%; /* Slightly reduced width */
}

.team-member {
     display: flex;
     align-items: center;
     padding: 20px 24px; /* Reduced padding */
     gap: 16px;
     background: #f3f3f3;
     border-radius: 8px;
     cursor: pointer;
     transition: background 0.3s ease;
}

/* Team Details adjustments */
.team-details {
     display: flex;
     flex-direction: column;
     background: #f3f3f3;
     border-left: 1px solid #dadada;
     padding: 16px 32px; /* Reduced padding */
     gap: 24px;
     flex-grow: 1;
     overflow-y: auto;
     max-height: 700px; /* Added max height */
}

.detail-header {
     display: flex;
     align-items: flex-start; /* Changed from center */
     gap: 24px;
     margin-bottom: 24px;
}

.detail-info {
     display: flex;
     flex-direction: column;
     gap: 8px;
}

.detail-name {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 32px; /* Reduced from 40px */
     font-weight: 700;
     color: #001831;
     line-height: 1.2;
}

.detail-body {
     padding-right: 16px; /* Added padding for scrollbar */
}

.detail-body .detail-section {
     margin-bottom: 32px;
}

.detail-body p {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 14px;
     font-weight: 500;
     line-height: 1.85;
     color: #696969;
     margin-top: 8px;
}

 /* Section styling */
.contact-section {
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 80px 300px 96px;
     position: relative;
     background-color: #F3F3F3; /* Background for the whole section */
   }
   
   .contact-container {
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     width: 100%;
     height: 617px; /* Height of the section */
     background: linear-gradient(90deg, #2ECC71 0%, #6AF6A5 100%), #2ECC71;
     border-radius: 20px;
     position: relative;
     overflow: visible; /* Allows the image to overflow */
   }
   
   /* Text container */
   .contact-text {
     padding: 80px 0 80px 80px;
     max-width: 50%;
     z-index: 2;
   }
   
   .contact-text .tagline {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 13px;
     font-weight: 600;
     text-transform: uppercase;
     letter-spacing: 0.2em;
     color: #FFFFFF;
   }
   
   .contact-text h1 {
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 40px;
     font-weight: 700;
     color: #FFFFFF;
     line-height: 1.25;
   }
   
   .contact-btn {
     display: inline-block;
     padding: 12px 24px;
     font-family: 'Plus Jakarta Sans', sans-serif;
     font-size: 15px;
     font-weight: 700;
     color: #2ECC71;
     background-color: #FFFFFF;
     border-radius: 99px;
     text-decoration: none;
     margin-top: 20px;
     transition: background-color 0.3s ease;
   }
   
   .contact-btn:hover {
     background-color: #DFFFD6;
   }
   
   /* Image container */
   .contact-image {
     position: absolute;
     right: -50px; /* Extend outside the green box */
     bottom: -100px; /* Subtract the bottom padding of 96px */
     z-index: 1;
   }
   

   .contact-image img {
     height: 100%; /* Ensure the image fills the height of the section */
     object-fit: contain; /* Maintain aspect ratio */
   }
   

   /* Example button styling */
.btn-success {
  color: #FFF;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 99px;
  background: #2ECC71;
  text-decoration: none; /* Ensure the button is styled correctly */
}

.btn-success:hover {
  background-color: #fff;
  color: #2ECC71;
}

.nav-items li {
    display: flex;
}

.nav-link {
    display: block;
    color: #FFF;
    width: 100%;
    text-decoration: none;
}

.nav-items li a,
.nav-items li Link {
    display: block;
    width: 100%;
    text-decoration: none;
}

/* Footer nav links */
.footer .nav-link {
  color: #FFFFFF;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer .nav-link:hover {
  color: #2ECC71;  /* your green accent color for hover state */
  opacity: 0.8;
}

/* Team member photos */
.member-photo {
     width: 60px;
     height: 60px;
     min-width: 60px;  /* Added to prevent squishing */
     min-height: 60px; /* Added to prevent squishing */
     border-radius: 50%;
     overflow: hidden;  /* Added to ensure content stays within circle */
     flex-shrink: 0;   /* Added to prevent flexbox from squishing */
}

.detail-photo {
     width: 120px;
     height: 120px;
     min-width: 120px;  /* Added to prevent squishing */
     min-height: 120px; /* Added to prevent squishing */
     border-radius: 50%;
     overflow: hidden;  /* Added to ensure content stays within circle */
     flex-shrink: 0;   /* Added to prevent flexbox from squishing */
}
