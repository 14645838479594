/* About Page Container */
.about-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 120px 0 40px;
}

/* Mission Section */
.mission-section {
  background: #f3f3f3;
  border-radius: 8px;
  padding: 48px;
  margin: 0 48px 48px;
}

.mission-section h2 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #001831;
  margin-bottom: 24px;
}

.mission-section p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #696969;
  max-width: 800px;
}

/* Values Section */
.values-section {
  padding: 0 48px;
  margin-bottom: 48px;
}

.values-section h2 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #001831;
  margin-bottom: 32px;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
}

.value-card {
  background: #f3f3f3;
  border-radius: 8px;
  padding: 32px;
  transition: transform 0.2s ease;
}

.value-card:hover {
  transform: translateY(-4px);
}

.value-card h3 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #001831;
  margin-bottom: 16px;
}

.value-card p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #696969;
}

/* Story Section */
.story-section {
  background: #f3f3f3;
  border-radius: 8px;
  padding: 48px;
  margin: 48px 48px 0;
}

.story-section h2 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #001831;
  margin-bottom: 24px;
}

.story-section p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #696969;
  margin-bottom: 16px;
}

.story-section p:last-child {
  margin-bottom: 0;
}

/* General section content wrapper */
.section-content {
  max-width: 1200px;
  margin: 0 auto;
}

/* Team section adjustments for About page */
.about-container .team-section {
    padding: 0;  /* Remove padding since about-container handles spacing */
    margin-bottom: 48px;
}

.about-container .team-container {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    background: #f3f3f3;
    border-radius: 8px;
    padding: 32px;
    gap: 32px;
    min-height: auto; /* Let it adjust to content */
    height: auto;
}

.about-container .team-details {
    display: flex;
    flex-direction: column;
    background: #f3f3f3;
    border-left: 1px solid #dadada;
    padding: 16px 32px;
    gap: 24px;
    flex-grow: 1;
    max-height: 400px;
}

.about-container .team-title {
    padding: 0 0 24px 0;
    font-size: 32px;
    font-weight: 700;
    color: #001831;
}

.team-list {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.team-member {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 16px;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.team-member:hover {
  background: rgba(46, 204, 113, 0.1);
  transform: translateY(-2px);
}

.member-photo {
  width: 48px;
  height: 48px;
  background-size: cover;
  border-radius: 50%;
}

.detail-header {
  display: flex;
  align-items: center;
  gap: 24px;
}

.detail-photo {
  width: 97px;
  height: 97px;
  background-size: cover;
  border-radius: 50%;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .team-container {
    flex-direction: column;
  }

  .team-list {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .about-container {
    padding-top: 100px;
  }

  .values-grid {
    grid-template-columns: 1fr;
  }
} 