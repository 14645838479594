@media (max-width: 1600px) {}


@media (max-width: 1365px) {}


@media (max-width: 1199px) {
     footer .row {
          grid-gap: 33px;
     }

     .hero-sec {
          padding: 154px 0px 64px;
     }

     .number-box {
          padding: 0px 24px;
     }

     footer {
          padding: 80px 0px 32px;
     }

     .footer-items {
          grid-gap: 7px;
     }
}


@media (max-width: 991px) {
     .text-box h1 {
          font-size: 48px;
          max-width: 645px;
     }

     .number-box {
          padding: 0px 16px;
     }

     .number-box h4 {
          font-size: 24px;
          margin-bottom: 8px;
     }

     .number-box h4 span {
          font-size: 12px;
     }

     .partner-logo {
          grid-gap: 40px;
     }

     .btns-list {
          margin: 24px 0px 60px;
     }

     .hero-sec {
          padding: 128px 0px 64px;
     }

     footer .row {
          flex-wrap: wrap;
     }

     .col-5 {
          width: 70%;
     }

     .social-links {
          padding: 20px 0px;
          margin: 48px 0px 26px;
     }

     .col-7 {
          width: 100%;
     }
}


@media (max-width: 767px) {

     .hamburger {
          display: block;
          width: 24px;
          height: 24px;
     }

     .number-boxes {
          display: block;
     }

     .col-3 {
          width: 100%;
     }

     .partner-sec .row {
          padding: 20px 0px;
          flex-wrap: wrap;
     }

     .col-9 {
          width: 100%;
          margin-top: 20px;
     }

     .number-box {
          padding: 16px 0px;
          border-left: none;
          width: 100%;
          border-top: 2px solid #2ECC71;
     }

     .hamburger img {
          filter: invert(1);
     }

     .navbar-nav {
          display: none;
          background: #050B1B;
          position: absolute;
          top: 50px;
          width: 100%;
          left: 0;
          padding: 20px;
     }

     .nav-items .nav-link {
          padding: 12px 0px;
     }

     .nav-items {
          display: block;
          text-align: center;
     }

     .navbar-nav.menu-open {
          display: block;
     }

     .footer-bottom {
          flex-wrap: wrap;
          grid-gap: 20px;
     }

     .footer-bottom p {
          order: 2;
     }

     .social-links {
          padding: 14px 0px;
          margin: 24px 0px 24px;
     }

     .footer-items {
          flex-wrap: wrap;
          grid-gap: 20px;
     }

     .footer-bottom ul {
          order: 1;
     }
}


@media (max-width: 575px) {
     .text-box h1 {
          font-size: 32px;
     }

     .btns-list {
          margin: 24px 0px 28px;
     }

     header {
          padding: 12px 0px;
          margin-top: 12px;
     }

     .partner-logo {
          grid-gap: 16px;
     }

     footer {
          padding: 48px 0px 32px;
     }

     .col-5 {
          width: 100%;
     }

     .hero-sec {
          padding: 92px 0px 32px;
     }
}

@media (max-width: 375px) {}