.contact-page {
  padding: 120px 20px 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-header {
  text-align: center;
  margin-bottom: 48px;
}

.contact-header h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 16px;
}

.contact-header p {
  font-size: 1.2rem;
  color: #666;
}

.contact-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 48px;
  background: white;
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-info {
  padding-right: 32px;
  border-right: 1px solid #eee;
}

.info-item {
  margin-bottom: 32px;
}

.info-item h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 8px;
}

.info-item p {
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 600;
  color: #333;
}

.form-group input,
.form-group textarea {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #2ECC71;
}

.submit-btn {
  background: #2ECC71;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-btn:hover {
  background: #25a25a;
}

.success-message {
    margin-top: 20px;
    padding: 15px;
    background-color: #e0f2e9;
    color: #156c3c;
    border-radius: 8px;
    text-align: center;
}

.error-message {
    margin-top: 20px;
    padding: 15px;
    background-color: #fde8e8;
    color: #c81e1e;
    border-radius: 8px;
    text-align: center;
}

.submit-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-content {
    grid-template-columns: 1fr;
  }

  .contact-info {
    padding-right: 0;
    border-right: none;
    border-bottom: 1px solid #eee;
    padding-bottom: 32px;
  }

  .info-item {
    margin-bottom: 24px;
  }
}

@media (max-width: 480px) {
  .contact-page {
    padding: 100px 16px 32px;
  }

  .contact-header h1 {
    font-size: 2rem;
  }

  .contact-content {
    padding: 24px;
  }
} 